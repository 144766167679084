import { useRouter } from 'next/router';
import Link from 'next/link';
import { appRoutes } from 'constants/appRoutes';
import { reportUserClickedOnEvent } from 'utils/analytics/segment';
import { ReactElement, Fragment } from 'react';
import classNames from 'classnames';
import useScrollPosition from 'hooks/useScrollPosition';
import LogoSVG from '../../../public/images/logotype.svg';
import { Popover, Transition } from '@headlessui/react'

type Props = {
  isTransparent?:  boolean;
}

const navigation = [
  { name: 'About', href: '/#about' },
  { name: 'Pricing', href: '/#pricing' },
  { name: 'Blog', href: '/blog' },
];

const MobileNavLink = ({ href, children }): ReactElement => {
  return (
    <Popover.Button as={Link} href={href} className="block w-full p-2 hover:text-blue-600">
      {children}
    </Popover.Button>
  )
}

const MobileNavIcon = ({ open }): ReactElement => {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={classNames(
          'origin-center transition',
          open && 'scale-90 opacity-0'
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={classNames(
          'origin-center transition',
          !open && 'scale-90 opacity-0'
        )}
      />
    </svg>
  )
}

const MobileNavigation = (): ReactElement => {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 mx-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            {navigation.map((link) => (
              <MobileNavLink href={link.href} key={"mobile-nave"+ link.name}>
                {link.name}
              </MobileNavLink>
            ))}
            <hr className="m-2 border-slate-300/40" />
            <MobileNavLink href="/help-centre">Contact Us</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

const VisitorHeader = ({ isTransparent = false }: Props): ReactElement => {
  const { pathname } = useRouter();
  const scrollPosition = useScrollPosition();
  const hasScrolled = scrollPosition !== 0;
  const isHomePage = (pathname === '/') || (pathname.startsWith('/#'));
  const isBlueTheme = !isHomePage || hasScrolled;

  return (
    <nav className={classNames(
      isTransparent ? styles.root.transparent : styles.root.default,
      isBlueTheme ? styles.root.scrolled : styles.root.notScrolled,
      )}>
      <div className={styles.container}>
          {/* Left - Logo */}
          <div className={styles.left}>
            <Link href={appRoutes.HOME} className={styles.logoHref}>
              <LogoSVG
                className={classNames(
                  styles.logoImg, isBlueTheme ?
                  "text-black" :
                  "text-black lg:text-white")
                } />
            </Link>
          </div>

          <div className="hidden space-x-8 lg:block mt-5">
            {navigation.map((link) => (
              <Link
                key={link.name}
                href={link.href}
                scroll={false}
                className={classNames(
                  "font-medium px-3 py-1 rounded-full transition ease-in-out duration-500",
                  isBlueTheme ?
                    "text-black hover:bg-gray-100 hover:text-blue-600" :
                    "text-white hover:bg-blue-100 hover:text-blue-600"
                )}>
                {link.name}
              </Link>
            ))}
          </div>

          {/* Right */}
          <div className={styles.right}>
            <Link
              href={appRoutes.HELP_CENTRE}
              className={classNames(
                isTransparent ?
                  styles.cta.transparent :
                  styles.cta.default,
                isBlueTheme ?
                  styles.cta.scrolled :
                  styles.cta.notScrolled
              )}
              onClick={() => reportUserClickedOnEvent('Get In Touch', 'Homepage Header')}>
                <span className={classNames(
                  "transition-all duration-500 inline-block text-right",
                  isBlueTheme ?
                    "w-5 opacity-100 animate-wiggle" :
                    "w-0 opacity-0"
                )}>
                  👋
                </span>
                <span className="inline-block pl-1.5">
                  Get In Touch
                </span>
            </Link>
            <div className="ml-4 md:hidden">
              <MobileNavigation />
            </div>
          </div>

      </div>
    </nav>
  );
}

export default VisitorHeader

const styles = {
  container: 'max-w-7xl mx-auto flex justify-between h-16 px-4 sm:px-6 lg:px-8',
  left: 'flex',
  logoHref: 'flex items-center',
  logoImg: 'block h-8 w-auto transition ease-in-out duration-500',
  right: 'flex items-center lg:min-w-[200px]',
  root: {
    default: 'fixed top-0 w-full z-20 transition ease-in-out duration-500 shadow-sm',
    transparent: 'fixed bg-transparent top-0 w-full z-20 border-b border-transparent',
    scrolled: 'bg-white border-b border-gray-200 lg:border-gray-200 lg:shadow-sm',
    notScrolled: 'bg-white lg:bg-blue-600 lg:border-transparent lg:shadow-none'
  },
  cta: {
    default: 'block w-full py-1.5 px-4 text-center border rounded-full text-base font-medium sm:inline-block sm:w-auto transition duration-200 ease-in-out',
    scrolled: 'border-black hover:border-transparent text-black hover:text-white hover:bg-blue-600',
    notScrolled: 'border-black hover:border-transparent lg:border-white text-black lg:text-white hover:text-white lg:hover:text-blue-600 bg-transparent hover:bg-black lg:hover:bg-white',
    transparent: 'block w-full py-1.5 px-4 text-center bg-transparent border border-white rounded-md text-base font-medium text-white hover:bg-black hover:text-white sm:inline-block sm:w-auto transition duration-200 ease-in-out'
  }
}