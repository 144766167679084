import { useWindowSize, WindowSize } from 'hooks';
import Link from 'next/link';
import { ReactElement } from 'react';
import { Parallax } from 'react-scroll-parallax';
import Image from 'next/image';

const Solution = (): ReactElement => {
  const size = useWindowSize();

  const disableParallax = (size: WindowSize): boolean => {
		return size.width <= 1200;
	}

  return (
    <div id="solutions" className="relative pb-20 bg-blue-600">

        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start pt-20 lg:pt-32">

          {/* Absolute Background Rectangle */}
          {/* <div className="absolute inset-y-0 bg-[#F9FAFB] right-0 mt-40 rounded-tl-3xl w-80" /> */}
          <div className="absolute inset-y-0 bg-blue-300/20 lg:bg-blue-300/70 right-0 mt-40 rounded-tl-3xl w-80" />

          {/* Absolute Pattern */}
          <Image
            className="absolute top-8 right-1/2 -ml-3 lg:m-0 lg:right-40 gray-200 opacity-100 pt-12 hidden lg:block"
            src={'/images/pattern.svg'}
            alt="Pattern"
            width={404}
            height={392}
          />

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <Parallax translateY={[20, -10]} disabled={disableParallax(size)}>
              <div className="pt-0 sm:pt-0 lg:pt-0">
                <h2 className="text-3xl text-white font-extrabold tracking-tight sm:text-4xl tracking-normal">
                  Our Solution
                </h2>
                <div className="mt-6 text-white space-y-6">
                  <p className="text-xl">
                    2Arrows offers excellent Technical Leadership solutions for startups & scale ups.
                  </p>
                  <p className="text-xl">
                    Through an innovative <b>Leadership As A Service</b> model, we scale excellent Technical Leadership in your business through a <Link href="/#pricing" className="underline">Pay As You Grow</Link> structure.
                  </p>
                  {/* <p className="text-xl">
                    Such structure allows your business to afford great Technical Leadership at whatever stage your company is at.
                  </p> */}
                  <p className="text-xl">
                    The approach is tailored to your business needs, either through the coaching & mentoring of leaders already in place, or through a more hands-on immersive approach.
                  </p>
                  {/* <p className="text-xl">
                    To provide utmost quality, our Technical Leaders bring experience from working within failed and successful startups alike.
                  </p> */}
                  <p className="text-xl">
                    The Technical leader is part of your Team at your location, immersed in the day to day, in view of the problems to be solved (ref. <Link className="underline" href="https://en.wikipedia.org/wiki/Genchi_Genbutsu">Genchi Genbutsu</Link>), as opposed to a more traditional & detached consulting approach.
                  </p>
                </div>
              </div>
            </Parallax>
          </div>

          <div className="relative py-4 lg:py-0">
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20" />
          </div> 

        </div>
      </div>
  );
}

export default Solution;