import { ReactElement } from 'react';
import Team from './components/Team';
import Hero from './components/Hero';
import BlurryBackground from './components/BlurryBackground';
import Brands from './components/Brands';
import Mission from './components/Mission';
import Features from './components/Features';
import Problem from './components/Problem';
import Solution from './components/Solution';
import Pricing from './components/Pricing';
import Advisors from './components/Advisors';

const Home = (): ReactElement => {
  return (
    <>
      {/* <BlurryBackground />  */}
      <div className="relative">
        <Hero />
        <Brands />
        <Problem />
        <Solution />
        <Mission />
        <Features />
        <Pricing />
        <Team />
        {/* <Advisors /> */}
      </div>
    </>
  )
}

export default Home