import { ReactElement, useEffect } from 'react'
import { useRouter } from 'next/router'
import { isSegmentImplemented, reportPageView } from 'utils/analytics/segment'

export const SegmentHOC = ({ children }: {children: any}): ReactElement => {
  const router = useRouter()

  useEffect(() => {
    if (!isSegmentImplemented()) return;

    const handleRouteChange = () => {
        reportPageView()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return children
}