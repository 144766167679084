import { useWindowSize, WindowSize } from 'hooks';
import { ReactElement } from 'react';
import { Parallax } from 'react-scroll-parallax';
import Image from 'next/image';

const Mission = (): ReactElement => {
  const size = useWindowSize();

  const disableParallax = (size: WindowSize): boolean => {
		return size.width <= 1200;
	}

  return (
    <div id="mission" className="relative mt-10 lg:mt-32 pb-20">

        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">

          {/* Absolute Background Rectangle */}
          <div className="absolute inset-y-0 bg-[#F9FAFB]/40 lg:bg-[#F9FAFB]left-0 mt-0 rounded-r-3xl w-80" />

          {/* Absolute Pattern */}
          <Image
            className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-40 gray-200 opacity-100 pt-12 hidden lg:block"
            src={'/images/pattern.svg'}
            alt="Pattern"
            width={404}
            height={392}
          />

          <div className="relative py-4 lg:py-0">
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
            </div>
          </div> 

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <Parallax translateY={[20, -10]} disabled={disableParallax(size)}>
              <div className="pt-0 sm:pt-0 lg:pt-0">
                <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl tracking-normal">
                  Our Mission
                </h2>
                <div className="mt-6 text-gray-700 space-y-6">
                  <p className="text-xl leading-8">
                    To immerse <b>Technical Excellence</b> & <b>Leadership</b> in startups & scale ups <b>early</b> to ensure both <b>short term</b> & <b>long term</b> technology success.
                  </p>
                </div>
              </div>
            </Parallax>
          </div>
        </div>
      </div>

  );
}

export default Mission;