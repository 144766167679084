import { useWindowSize, WindowSize } from 'hooks';
import { ReactElement } from 'react';
import { Parallax } from 'react-scroll-parallax';
import Image from 'next/image';

const Problem = (): ReactElement => {
  const size = useWindowSize();

  const disableParallax = (size: WindowSize): boolean => {
		return size.width <= 1200;
	}

  return (
    <div className="relative mt-10 lg:mt-32 pb-20">

        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">

          {/* Absolute Background Rectangle */}
          <div className="absolute inset-y-0 bg-[#F9FAFB]/40 lg:bg-[#F9FAFB] left-0 mt-0 rounded-r-3xl w-80" />

          {/* Absolute Pattern */}
          <Image
            className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-40 gray-200 opacity-100 pt-12 hidden lg:block"
            src={'/images/pattern.svg'}
            alt="Pattern"
            width={404}
            height={392}
          />

          <div className="relative py-4 lg:py-0">
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
            </div>
          </div> 

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <Parallax translateY={[20, -10]} disabled={disableParallax(size)}>
              <div className="pt-0 sm:pt-0 lg:pt-0">
                <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl tracking-normal">
                  The Problem we solve
                </h2>
                <div className="mt-6 text-gray-700 space-y-6">
                  <p className="text-xl">
                    It can be difficult for startups & scale ups to get Technical Leadership early in their business. Specifically in Australia, good technical Leadership is hard to find, time consuming and expensive.
                  </p>
                  <p className="text-xl">
                    Typically, a lack of Technical Leadership results in increasingly difficult communication between Business & Tech, a lack of vision & focus, and the reinforcement of bad habits that are difficult to change.
                  </p>
                </div>
              </div>
            </Parallax>
          </div>
        </div>
      </div>

  );
}

export default Problem;