import Home from 'templates/Home'
import Head from 'next/head';
import { getLayout } from 'layouts/Default'
import { BackgroundColor } from 'utils/ColorUtils'
import { NextPageWithLayout } from './_app'

const HomePageMeta = (): JSX.Element => {
  return (
    <Head>
      <title>Technology Leadership in your Business | 2Arrows</title>
      <meta name="description" content="We offer top-notch Technology Expertise and Leadership embedded in your business."/>
      <meta name="keywords" content="startup, scale up, technical leadership, technical leaders, technology, CTO, Head of Engineering, Chief Technical Officer" />

      <meta property="og:title" content="Technology Leadership in your Business." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={process.env.NEXT_PUBLIC_BASE_URI} />
      <meta property="og:locale" content="en_AU" />
      <meta property="og:site_name" content="2Arrows" />
      <meta property="og:image" content={process.env.NEXT_PUBLIC_BASE_URI + "/images/Technology-Leadership-in-your-Business-2Arrows.png"} />
      <meta property="og:description" content="We offer top-notch Technology Expertise and Leadership embedded in your business." />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content="Technology Leadership in your Business." />
      <meta property="twitter:description" content="We offer top-notch Technology Expertise and Leadership embedded in your business." />
      <meta property="twitter:image" content={process.env.NEXT_PUBLIC_BASE_URI + "/images/Technology-Leadership-in-your-Business-2Arrows.png"} />
      <meta property="twitter:site" content="@2ArrowsTech" />
    </Head>
  )
}

const HomePage: NextPageWithLayout = () => {
  return (
    <>
      <HomePageMeta />
      <Home />
    </>
  )
}

HomePage.backgroundColor = BackgroundColor.White
HomePage.getLayout = getLayout

export default HomePage

