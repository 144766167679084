import { useWindowSize, WindowSize } from 'hooks';
import { ReactElement } from 'react';
import { Parallax } from 'react-scroll-parallax';
import CalendlyBtn from 'elements/CalendlyBtn';
import Typewriter from 'typewriter-effect';
import HeroSVG from '../../../../public/images/hero.svg';
import useScrollPosition from 'hooks/useScrollPosition';
import classNames from 'classnames';

const Hero = (): ReactElement => {
  const size = useWindowSize();
  const scrollPosition = useScrollPosition();
  const hasScrolled = scrollPosition !== 0;

  const disableParallax = (size: WindowSize): boolean => {
		return size.width <= 1200;
	}

  const messages = ['Startup', 'Scale up', 'Business.'];

  return (
    <div className={classNames("pt-20 pb-4 lg:pb-20 lg:relative lg:py-48 transition ease-in-out duration-500 overflow-hidden", hasScrolled ? "bg-white" : "bg-blue-600")}>
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24 pb-8">
        <div>
          <div className="mt-10">
            <div className="mt-6 sm:max-w-xl mx-auto">
              <Parallax translateY={[20, -20]} disabled={disableParallax(size)}>
                <h1 className={classNames("font-extrabold tracking-tight text-center tracking-normal transition ease-in-out duration-500", hasScrolled ? "text-gray-900":"text-white" )}>
                  <span className="text-2xl sm:text-3xl">Technology</span>
                  {' '}
                  <span className={classNames("text-2xl sm:text-3xl relative whitespace-nowrap inline-block transition ease-in-out duration-500", hasScrolled ? "text-blue-600" : "text-white")}>
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 418 42"
                      className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70"
                      preserveAspectRatio="none"
                    >
                      <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                    </svg>
                    <span className="relative">Leadership</span>
                  </span>
                  <span className="text-xl sm:text-2xl block">{'  '}</span>
                  <span className="text-2xl sm:text-3xl inline-block mr-2">{'in your'}</span>
                  <span className={classNames("text-2xl sm:text-3xl relative whitespace-nowrap inline-block transition ease-in-out duration-500", hasScrolled ? "text-blue-600" : "text-white")}>
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 418 42"
                      className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70"
                      preserveAspectRatio="none"
                    >
                      <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                    </svg>
                    <span className="relative">
                      <div className="inline">
                        <Typewriter
                          onInit={typewriter => {
                            messages.slice(0, messages.length - 1).forEach(message => {
                              typewriter.typeString(message).pauseFor(1000).deleteAll()
                            })
                            typewriter
                              .typeString(messages[messages.length - 1])
                              .pauseFor(1000)
                              .start()
                          }}
                        />

                      </div>
                      {/* Startup */}
                    </span>
                  </span>
                </h1>
              </Parallax>
              <p className={classNames("mt-6 text-xl text-center leading-9 transition ease-in-out duration-500", hasScrolled ? "text-gray-700":"text-white")}>
                We offer top-notch <b>Technology Expertise</b> and <b>Leadership</b> embedded in your business.
              </p>
            </div>

            <div className="mt-12 text-center" id="calendly">
              <CalendlyBtn
                selector="#calendly"
                buttonClassName={classNames(
                  "py-1.5 px-4 text-center border rounded-full text-base font-medium inline-block w-auto transition duration-200 ease-in-out",
                  hasScrolled ? "border-transparent hover:border-blue-600 bg-blue-600 hover:bg-white text-white hover:text-blue-600":"text-blue-600 hover:text-white border-white bg-white hover:bg-blue-600"
                )} />
            </div>
          </div>
        </div>
      </div>

      <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
        <div className="sm:relative mt-0 lg:mt-12 py-0 lg:py-12 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">

          {/* Absolute Background Rectangle */}
          <div className={classNames("hidden lg:block absolute inset-y-0 ml-4 lg:left-80 mt-12 rounded-3xl mb-28 w-80 transition ease-in-out duration-500", hasScrolled ? "bg-[#F9FAFB]" : "bg-blue-300/20")} />

          {/* Hero Artwork */}
          <div className="relative sm:max-w-3xl lg:max-w-none lg:h-full">
            <Parallax translateY={[-40, 30]} disabled={disableParallax(size)}>
              <HeroSVG
                width={530}
                height={350}
                className={classNames("w-full lg:h-full lg:w-auto lg:max-w-none transition ease-in-out duration-500", hasScrolled ? "text-black" : "text-white/90")} />
            </Parallax>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;