import { ReactElement } from 'react'
import { useActor } from "@xstate/react"
import { NotificationService } from "services/NotificationService"
import Notification from 'elements/Notification'

const Notifications = (): ReactElement => {
  const [state] = useActor(NotificationService)

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-4 pointer-events-none sm:px-6 md:py-20 sm:items-start"
      >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {state.context.notifications
          .map(notification => 
            <Notification key={notification.id} notification={notification} />
        )}
      </div>
    </div>
  )
}

export default Notifications