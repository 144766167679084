import { interpret } from 'xstate';
import { EventType, modalMachine, ModalView } from 'machines/ModalMachine';

export const ModalService = interpret(modalMachine);
ModalService.start();

export const closeModal = (): void => {
  ModalService.send(EventType.RequestClose)
}

export const openActionableModal = (): void => {
  ModalService.send(EventType.RequestOpen, {
    view: ModalView.Actionable,
  })
}