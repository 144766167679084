import { useRouter } from "next/router";

const hasSSREnabled = (pathname: string): boolean => {

  const enabledSSRPaths = [
    '/',
    '/privacy-policy',
    'terms-of-service'
  ];
  
  return enabledSSRPaths.includes(pathname);
}
  
export const useHasSSREnabled = () => {
  const { pathname } = useRouter();

  return hasSSREnabled(pathname);
}
