import React, { ReactElement } from 'react';
import { Parallax } from 'react-scroll-parallax';
import Image from 'next/image';
import LeadershipIcon from '../../../../public/images/icons/icon-leadership.svg';
import ExpertiseIcon from '../../../../public/images/icons/icon-expertise.svg';
import EmbeddedIcon from '../../../../public/images/icons/icon-embedded.svg';
import { useWindowSize, WindowSize } from 'hooks';
  
const features = [
  {
    name: 'Technology Leadership',
    description: 'Our Technical Leaders have experience working in fast-pace & challenging environments.',
    Icon: LeadershipIcon,
  },
  {
    name: 'Technology Expertise',
    description: 'Our Technical Leaders have expertise in a wide-range of technologies.',
    Icon: ExpertiseIcon,
  },
  {
    name: '→ Embedded in your business',
    description: 'Our Technical Leaders are immersed in your business.',
    Icon: EmbeddedIcon,
  }
];

const Features = (): ReactElement => {
  const size = useWindowSize();

  const disableParallax = (size: WindowSize): boolean => {
		return size.width <= 1200;
	}
  
  return (
    <div id="about" className="relative bg-black py-24 sm:py-24">
      <div className="mx-auto max-w-xl px-6 text-center sm:max-w-5xl lg:max-w-6xl lg:px-8">
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Two Arrows
        </h2>
        <p className="mx-auto mt-5 max-w-prose text-xl text-white leading-9">
                  <span className="text-xl">{'We provide '}</span>
                  <span className="text-xl font-bold relative whitespace-nowrap text-white">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 418 42"
                      className="absolute top-3/3 left-0 h-[0.58em] w-full fill-gray-300/70 hidden lg:flex"
                      preserveAspectRatio="none"
                    >
                      <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                    </svg>
                    <span className="relative">Technology Leadership</span>
                  </span>
                  <span className="text-xl">{' and '}</span>

                  <span className="text-xl font-bold relative whitespace-nowrap text-white">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 418 42"
                      className="absolute top-3/3 left-0 h-[0.58em] w-full fill-gray-300/70 hidden lg:flex"
                      preserveAspectRatio="none"
                    >
                      <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                    </svg>
                    <span className="relative">Technical Expertise</span>
                  </span>
                  <span className="text-xl">{' in your Business. '}</span>
        </p>
        <div className="mt-20">


          <div className="mx-auto mt-12 grid gap-8 md:gap-4 lg:gap-6 max-w-sm md:max-w-none grid-cols-1 md:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col overflow-hidden rounded-lg shadow-lg text-center">
                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                  <div className="flex-1">

                    <Parallax translateY={[20, -20]} disabled={disableParallax(size)}>
                      <span className="inline-flex items-center justify-center rounded-xl p-3 mb-3">
                        <feature.Icon />
                      </span>
                    </Parallax>

                    <div className="mt-2 block">
                      <Parallax translateY={[-20, 20]} disabled={disableParallax(size)}>
                        <p className="text-lg font-semibold text-gray-900 my-3">
                          {feature.name}
                        </p>
                      </Parallax>
                      <p className="text-base mt-3 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  )
}

export default Features;