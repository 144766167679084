import React, { FC, useEffect, useRef, useState } from 'react'
import { PopupModal } from 'react-calendly'
import { RocketLaunchIcon } from '@heroicons/react/24/outline'
import { PageSettings, Utm, Prefill } from "react-calendly/typings/calendly";

interface CalendlyBtnProps {
  selector: string;
  url?: string;
  pageSettings?: PageSettings;
  utm?: Utm;
  prefill?: Prefill;
  rootClassName?: string;
  buttonClassName?: string;
}

const CalendlyBtn: FC<CalendlyBtnProps> = ({
  selector,
  url = "https://calendly.com/anthony-rey/15min",
  pageSettings = {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '2563EB',
    textColor: '4d5055'
  },
  utm={},
  prefill={},
  rootClassName="",
  buttonClassName="py-1.5 px-4 text-center border border-black rounded-full text-base font-medium inline-block w-auto"
}): JSX.Element => {

  const ref = useRef();
  const [mount, setMount] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setMount(true);
  }, [selector]);


  return mount ? (
    <div className={rootClassName}>
      <button
        style={{ display: "block", margin: "0 auto" }}
        className={buttonClassName}
        onClick={() => setIsOpen(true)}
      >
        <RocketLaunchIcon className="inline mr-2 mb-1" width="18" height="18" aria-hidden="true" />
        <span>Book 15&apos; Chat</span>
      </button>
      <PopupModal
        url={url}
        pageSettings={pageSettings}
        utm={utm}
        prefill={prefill}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
          * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
          * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
          */
        rootElement={document.querySelector(selector)}
      />
    </div>
  ) : null;
}

export default CalendlyBtn;