import { AnalyticsEventName, IAnalyticsEvent } from "./events";

export const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

declare global {
  interface Window {
    analytics: any;
  }
}

export const isSegmentImplemented = (): boolean => {
  return (SEGMENT_WRITE_KEY !== '');
}

export const reportPageView = (url: string = '') => {
  if (!isSegmentImplemented()) return;

  window.analytics.page(url);
}

export const reportEvent = (event: IAnalyticsEvent) => {
  if (!isSegmentImplemented()) return;

  window.analytics.track(event.name, event.properties);
}

// Events

export const reportUserClickedOnEvent = (title: string, location?: string) => reportEvent({
  name: AnalyticsEventName.USER_CLICKED,
  properties: {
    title,
    location
  }
});