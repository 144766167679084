import { interpret } from 'xstate'
import { notificationMachine } from 'machines/NotificationMachine'
import { Notification, NotificationKind } from 'models/Notification'
import {v4 as uuidv4} from 'uuid';

export const NotificationService = interpret(notificationMachine)
NotificationService.start()

export enum NotificationType {
  DROP_CREATED,
  DROP_UPDATED,
  DROP_DELETED,
  COLLECTION_CREATED,
  COLLECTION_UPDATED,
  COLLECTION_DELETED,
}

export const buildNotification = (type: NotificationType): Notification => {
  switch (type) {
    case NotificationType.DROP_CREATED:
      return {
        id: uuidv4(),
        kind: NotificationKind.Success,
        title: 'Memory Created',
        subTitle: 'Your memory was successfully created.',
        visible: true
      };
    case NotificationType.DROP_UPDATED:
      return {
        id: uuidv4(),
        kind: NotificationKind.Success,
        title: 'Memory Updated',
        subTitle: 'Your memory was successfully updated.',
        visible: true
      };
    case NotificationType.DROP_DELETED:
      return {
        id: uuidv4(),
        kind: NotificationKind.Success,
        title: 'Memory Deleted',
        subTitle: 'Your memory was successfully deleted.',
        visible: true
      };
    case NotificationType.COLLECTION_CREATED:
      return {
        id: uuidv4(),
        kind: NotificationKind.Success,
        title: 'Collection Created',
        subTitle: 'Your collection was successfully created.',
        visible: true
      };
    case NotificationType.COLLECTION_UPDATED:
      return {
        id: uuidv4(),
        kind: NotificationKind.Success,
        title: 'Collection Updated',
        subTitle: 'Your collection was successfully updated.',
        visible: true
      };
    case NotificationType.COLLECTION_DELETED:
      return {
        id: uuidv4(),
        kind: NotificationKind.Success,
        title: 'Collection Deleted',
        subTitle: 'Your collection was successfully deleted.',
        visible: true
      };
  }

}

// Notify the use with a notification
// If autoHide is set to true, the notification will disappear after `timeout` milliseconds
export const notify = (notificationType: NotificationType, autoHide: boolean = true, timeoutInMs: number = 1500): void => {
  const notification = buildNotification(notificationType);
  NotificationService.send({ type: 'NOTIFICATION.SHOW', notification })
  if (autoHide) {
    setTimeout(() => {
      denotify(notification);
    }, timeoutInMs)
  }
}

export const denotify = (notification: Notification): void => {
  NotificationService.send({ type: 'NOTIFICATION.HIDE', notification: notification})
}