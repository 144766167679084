import { useState, useEffect } from 'react';

export type WindowSize = {
    width: number;
    height: number;
}

export const useWindowSize = (): WindowSize => {

    // Initialize state with undefined width/height so server and client renders match
    // See https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      // Only execute all the code below in client side
      if (typeof window !== 'undefined') {

        // Handler to call on window resize
        const handleResize = () => {
          // Set window width/height to state
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
      
        // Add Event Listener
        window.addEventListener("resize", handleResize);
       
        // Call handler right away so state gets updated with initial window size
        handleResize();
      
        // Remove Event Listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
      }
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
  }