import { ReactElement, useId } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import lorenzoImage from 'public/images/avatars/lorenzo-tocco-2arrows.jpg';
import anthonyImage from 'public/images/avatars/anthony-rey-2arrows.jpg';
import { FaLinkedin } from 'react-icons/fa';
import Link from 'next/link';
import { Parallax } from 'react-scroll-parallax';
import { useWindowSize, WindowSize } from 'hooks';

function ImageClipPaths({ id, ...props }) {
  return (
    <svg aria-hidden="true" width={0} height={0} {...props}>
      <defs>
        <clipPath id={`${id}-0`} clipPathUnits="objectBoundingBox">
          <path d="M0,0 h0.729 v0.129 h0.121 l-0.016,0.032 C0.815,0.198,0.843,0.243,0.885,0.243 H1 v0.757 H0.271 v-0.086 l-0.121,0.057 v-0.214 c0,-0.032,-0.026,-0.057,-0.057,-0.057 H0 V0" />
        </clipPath>
        <clipPath id={`${id}-1`} clipPathUnits="objectBoundingBox">
          <path d="M1,1 H0.271 v-0.129 H0.15 l0.016,-0.032 C0.185,0.802,0.157,0.757,0.115,0.757 H0 V0 h0.729 v0.086 l0.121,-0.057 v0.214 c0,0.032,0.026,0.057,0.057,0.057 h0.093 v0.7" />
        </clipPath>
        <clipPath id={`${id}-2`} clipPathUnits="objectBoundingBox">
          <path d="M1,0 H0.271 v0.129 H0.15 l0.016,0.032 C0.185,0.198,0.157,0.243,0.115,0.243 H0 v0.757 h0.729 v-0.086 l0.121,0.057 v-0.214 c0,-0.032,0.026,-0.057,0.057,-0.057 h0.093 V0" />
        </clipPath>
      </defs>
    </svg>
  )
}

const Team = (): ReactElement => {
  const size = useWindowSize();

  const disableParallax = (size: WindowSize): boolean => {
		return size.width <= 1200;
	}

  const leaders = [
    {
      name: 'Anthony Rey',
      role: 'Ex. Tinybeans, Prezzee, VOLY',
      image: anthonyImage,
      linkedIn: "https://linkedin.com/in/anrey/"
    },
    {
      name: 'Lorenzo Tocco',
      role: 'Ex. SafetyCulture, WooliesX, VOLY',
      image: lorenzoImage,
      linkedIn: "https://www.linkedin.com/in/lorenzo-tocco/"
    }
  ];
  let id = useId();

  return (
    <div id="technology-leaders" className={styles.root}>
      <div className="mx-auto">

        <div className={styles.head.container}>
          <Parallax translateY={[20, -10]} disabled={disableParallax(size)}>
            <div className="pt-0 sm:pt-0 lg:pt-0">
              <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl tracking-normal">
                Our Technology Leaders
              </h2>
              <div className="mt-6 text-gray-700 space-y-6">
                <p className="text-xl">
                  Our Technology Leaders can&apos;t wait to meet you!
                </p>
              </div>
            </div>
          </Parallax>
        </div>

        <ImageClipPaths id={id} />

        <div className={styles.body.container}>
          <div className={styles.body.grid}>

            {leaders.map((leader, index) => (
              <div key={index}>
                <div className="group relative h-[17.5rem] transform overflow-hidden rounded-4xl">
                  <div
                    className={classNames(
                      'absolute top-0 left-0 right-4 bottom-6 rounded-4xl border transition duration-300 group-hover:scale-95 xl:right-6',
                      [
                        'border-blue-600',
                        'border-indigo-300',
                        'border-sky-300',
                      ][index % 3]
                    )}
                  />
                  <div
                    className="absolute inset-0 bg-indigo-50"
                    style={{ clipPath: `url(#${id}-${index % 3})` }}>
                    <Image
                      className="absolute inset-0 h-full w-full object-cover transition duration-300 group-hover:scale-110"
                      src={leader.image}
                      alt=""
                      priority
                      sizes="(min-width: 1280px) 17.5rem, (min-width: 1024px) 25vw, (min-width: 768px) 33vw, (min-width: 640px) 50vw, 100vw"
                    />
                  </div>
                </div>
                <h3 className="mt-8 font-display text-xl font-bold tracking-tight text-slate-900">
                  {leader.name}
                  <Link href={leader.linkedIn} rel="noopener noreferrer" target="_blank" className="inline-block ml-1">
                    <FaLinkedin />
                  </Link>
                </h3>
                <p className="mt-1 text-base tracking-tight text-slate-500">
                  {leader.role}
                </p>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Team;

const styles = {
  root: "flex my-8",
  head: {
    container: "relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 mb-14",
    title: "font-display text-3xl font-medium tracking-tighter text-black sm:text-4xl",
    subtitle: "mt-4 font-display text-xl tracking-tight text-slate-700"
  },
  body: {
    container: "lg:col-span-3 px-5",
    grid: "grid grid-cols-1 gap-x-4 sm:gap-x-8 gap-y-10 2xs:grid-cols-2 sm:gap-y-16 md:grid-cols-3 [&:not(:focus-visible)]:focus:outline-none"
  }
};