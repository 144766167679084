export enum AnalyticsEventName {
  USER_REGISTERED = 'User Registered',
  USER_LOGGED_IN = 'User Logged In',
  USER_LOGGED_OUT = 'User Logged Out',
  USER_CLICKED = 'User Clicked',
};

export interface IAnalyticsEvent {
  name: AnalyticsEventName;
  properties: Record<string,any>;
};
export type ClickedOnProperties = {
  title: string;
  location?: string;
};

export type ClickedOnEvent = IAnalyticsEvent & {
  name: AnalyticsEventName.USER_CLICKED,
  properties: ClickedOnProperties,
};

export type SegmentEvent = | ClickedOnEvent;