import React, { FC, Fragment, ReactElement } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { denotify } from "services/NotificationService"
import { Notification as NotificationModel } from 'models/Notification'

interface NotificationProps {
  notification: NotificationModel
}

const Notification: FC<NotificationProps> = ({
  notification,
}): ReactElement => {

  return (
    <Transition
      show={notification.visible}
      as={Fragment}
      enter="ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black/5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{ notification.title }</p>
              <p className="mt-1 text-sm text-gray-500">{ notification.subTitle }</p>
            </div>
            <div className="ml-4 shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => denotify(notification)}
              >
                <span className="sr-only">Close</span>
                <XCircleIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default Notification