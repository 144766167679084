import { ReactElement } from 'react';
import LogoPrezzeeMono from '../../../../public/images/brands/prezzee-mono.svg';
import LogoPrezzeeOriginal from '../../../../public/images/brands/prezzee-original.svg';
import LogoSafetyMono from '../../../../public/images/brands/safety-mono.svg';
import LogoSafetyOriginal from '../../../../public/images/brands/safety-original.svg';
import LogoTinybeansMono from '../../../../public/images/brands/tinybeans-mono.svg';
import LogoTinybeansOriginal from '../../../../public/images/brands/tinybeans-original.svg';
import LogoWooliesMono from '../../../../public/images/brands/woolies-mono.svg';
import LogoWooliesOriginal from '../../../../public/images/brands/woolies-original.svg';
import LogoVolyMono from '../../../../public/images/brands/voly-mono.svg';
import LogoVolyOriginal from '../../../../public/images/brands/voly-original.svg';
import LogoBigwMono from '../../../../public/images/brands/bigw-mono.svg';
import LogoBigwOriginal from '../../../../public/images/brands/bigw-original.svg';
import { Parallax } from 'react-scroll-parallax';
import { useWindowSize, WindowSize } from 'hooks';
import classNames from 'classnames';
import useScrollPosition from 'hooks/useScrollPosition';

const Brands = (): ReactElement => {
  const size = useWindowSize();
  const scrollPosition = useScrollPosition();
  const hasScrolled = scrollPosition !== 0;

  const disableParallax = (size: WindowSize): boolean => {
		return size.width <= 1200;
	}
  return (
    <div className={classNames("pb-10 mb-20 transition ease-in-out duration-500", hasScrolled ? "bg-white":"bg-blue-600")}>
      <div className={classNames("py-10 transition ease-in-out duration-500", hasScrolled ? "bg-[#F9FAFB]":"bg-blue-300/20")}>
          <p className={classNames("text-xl font-display text-center pb-8 px-4", hasScrolled ? "text-gray-700":"text-white")}>
            Proven startup & scale-up experience
          </p>
          <ul
            role="list"
            className="flex items-center justify-center gap-x-8 sm:flex-col gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0 overflow-hidden"
          >
            {[
              [
                { name: 'Prezzee', LogoMono: LogoPrezzeeMono, LogoOriginal: LogoPrezzeeOriginal },
                { name: 'SafetyCulture', LogoMono: LogoSafetyMono, LogoOriginal: LogoSafetyOriginal },
                { name: 'Tinybeans', LogoMono: LogoTinybeansMono, LogoOriginal: LogoTinybeansOriginal },
              ],
              [
                { name: 'WooliesX', LogoMono: LogoWooliesMono, LogoOriginal: LogoWooliesOriginal },
                { name: 'Voly', LogoMono: LogoVolyMono, LogoOriginal: LogoVolyOriginal },
                { name: 'BigWX', LogoMono: LogoBigwMono, LogoOriginal: LogoBigwOriginal },
              ],
            ].map((group, groupIndex) => (
              <li key={groupIndex}>
                <ul
                  role="list"
                  className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0"
                >
                  {group.map(({name, LogoMono, LogoOriginal}) => (
                    <Parallax translateX={[-40, 40]} disabled={disableParallax(size)} key={name}>
                      <li className="flex">
                        { hasScrolled ? 
                          <LogoOriginal /> :
                          <LogoMono className="text-white" />
                        }
                      </li>
                    </Parallax>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
  );
}

export default Brands;