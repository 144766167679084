import { useWindowSize, WindowSize } from 'hooks';
import { ReactElement } from 'react';
import { Parallax } from 'react-scroll-parallax';
import Image from 'next/image';
import Link from 'next/link';
import { appRoutes } from 'constants/appRoutes';

const Pricing = (): ReactElement => {
  const size = useWindowSize();

  const disableParallax = (size: WindowSize): boolean => {
		return size.width <= 1200;
	}

  const plans = [{
      title: 'Free',
      category: 'Any Team Size',
      details: [
        'Initial Conversation',
        'Technical Audit',
      ],
      price: {
        monthly: 'Free',
        yearly: 'Free',
      },
    },
    {
      title: 'Micro',
      category: '0-1 engineer',
      details: [
        'Technology Leadership',
        'Technical Expertise',
      ],
      price: {
        monthly: '$5k',
        yearly: '$60k',
      },
    },
    {
      title: 'Mini',
      category: "2-3 engineers",
      details: [
        'Technology Leadership',
        'Technical Expertise',
      ],
      price: {
        monthly: '$10k',
        yearly: '$120k',
      },
    },
    {
      title: 'Medium',
      category: "4-6 engineers",
      details: [
        'Technology Leadership',
        'Technical Expertise',
      ],
      price: {
        monthly: '$15k',
        yearly: '$180k',
      },
    },
    {
      title: 'Large',
      category: "7-14 engineers",
      details: [
        'Technology Leadership',
        'Technical Expertise',
      ],
      price: {
        monthly: '$20k',
        yearly: '$240k',
      },
    },
    {
      title: 'Extra Large',
      category: "15+ engineers",
      details: [
        'Technology Leadership',
        'Technical Expertise',
      ],
      price: {
        monthly: 'Request Quote',
        yearly: 'Request Quote',
      },
    },
  ];

  return (
    <div id="pricing" className="relative bg-[#F9FAFB] px-4 pt-20 pb-20 sm:px-6 lg:px-8 lg:pb-28">
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Leadership As A Service.
          </h2>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {/* Pay As You Grow. */}
          </h2>
          <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
            Pay As You Grow.
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-3xl gap-5 xl:max-w-none grid-cols-1 2xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6">
          {plans.map((plan) => (
            <div key={plan.title} className="flex flex-col overflow-hidden rounded-lg shadow-md text-center">
              <div className="flex flex-1 flex-col justify-between bg-white py-6 px-2">
                <div className="flex-1">
                  <p className="text-sm font-medium text-blue-600">
                    { plan.category }
                  </p>
                  <div className="mt-2 block">
                    <Parallax translateY={[20, -20]} disabled={disableParallax(size)}>
                      <p className="text-xl font-semibold text-gray-900">
                        {plan.title}
                      </p>
                    </Parallax>
                    <ul className="text-sm mt-3 text-base text-gray-500">
                      { plan.details.map((detail) =>
                        <li className="pt-4" key={detail}>{detail}</li>
                      )}
                    </ul>
                  </div>
                </div>
                  { plan.price.monthly === "Request Quote" ?
                    <div>
                      <Link
                        href={appRoutes.HELP_CENTRE}
                        type="button"
                        className="inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 transition active:transition-none bg-zinc-800 font-semibold text-zinc-100 hover:bg-zinc-700 active:bg-zinc-800 active:text-zinc-100/70 ml-4 flex-none mb-2" >
                        <span>Request Quote</span>
                      </Link>
                    </div> :
                    <div className="mt-6 text-center">
                      <p className="text-lg font-medium text-gray-900">
                        {plan.price.monthly}
                      </p>
                      <p className="text-md text-gray-500">
                        { plan.title != "Free" ? "/month" : "\u00A0" }
                      </p>
                    </div>
                  }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Pricing;